import {
    BaseGenericRequest,
    getUrlEndpointPrivate,
} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';
import {RegistrationRequestAction} from '../RegistrationRequest';

export interface TaskRegistrationRequestManageRequest {
    action: RegistrationRequestAction,
}

export interface TaskRegistrationRequestManageResponse {
    code: number,
    success: boolean,
    error?: string,
}

export default class TaskRegistrationRequestManage extends BaseGenericRequest<{}, TaskRegistrationRequestManageResponse> {

    private readonly data;

    public constructor(data: TaskRegistrationRequestManageRequest, registrationRequestId: number) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate(`registrationrequest/manage/${registrationRequestId}`));
        this.data = data;
    }

    protected getRequest(): TaskRegistrationRequestManageRequest {
        return this.data;
    }
}