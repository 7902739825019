import {BookingDateId} from '../bookingDate/domain/valueObject/BookingDateId';
import LexusApiClient from '../../data/remote/lexus/LexusApiClient';
import DownloadBookingDatePdfUseCase from './domain/useCase/DownloadBookingDatePdfUseCase';
import {ApiBookingDateRepository} from '../bookingDate/infrastructure/repository/api/ApiBookingDateRepository';

interface UseCustomBookingDateScreenButtonListResponse {
    downloadPdf: () => void,
}

export const useCustomBookingDateScreenButtonListController = (
    bookingDateId: string,
): UseCustomBookingDateScreenButtonListResponse => {
    const downloadPdf = () => {
        const repository = new ApiBookingDateRepository(LexusApiClient());
        DownloadBookingDatePdfUseCase({
            bookingDateRepository: repository,
            bookingDateId: new BookingDateId(bookingDateId),
        })
            .then(async response => {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'booking.pdf';
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
    }

    return {
        downloadPdf,
    }
}