import React from 'react';
import {
    FormRenderData,
    Module,
    ModalSize,
    FilterSelectorValuesParams,
    FieldFValue,
    ScreenTypes,
} from '@cuatroochenta/co-admin-react-library';
import './res/scss/custom-styles.scss'
import '@cuatroochenta/co-admin-react-library/lib/index.css';
import {CustomBookingDateScreenButtonList} from './modules/bookingDate/CustomBookingDateScreenButtonList';
import {Status} from './modules/experience/component/Status';
import {CustomEditScreenButtonList} from './modules/experience/CustomEditScreenButtonList';
import {RegistrationRequest} from './modules/registrationRequest/RegistrationRequest';
import {ImportNivsScreen} from './actions/modules/importNivs/ImportNivsScreen';

export const App = ({}): React.ReactElement => {
    const beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const afterList = (): React.ReactElement => {
        return <></>;
    };

    const afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const getSizeFilterModal = (entity: string): ModalSize | undefined => {
        if (entity === 'user') {
            return ModalSize.LG;
        }
    };

    const filterSelectorValues = (
        filterSelectorValuesParams: FilterSelectorValuesParams
    ): FieldFValue[] => {
        return filterSelectorValuesParams.values;
    };

    const customScreenButtonList = (entity: string, type: string, data: any): React.ReactNode => {
        if (entity === 'registrationrequest') {
            if (type === ScreenTypes.EDIT) {
                return (
                    <RegistrationRequest
                        registrationRequestId={data.oid}
                    />
                )
            }
        }
        if (entity === 'experience') {
            if (type === ScreenTypes.EDIT) {
                return <CustomEditScreenButtonList data={data}/>;
            }
            if (type === ScreenTypes.VIEW) {
                return <Status status={data.status}/>;
            }
            return null;
        }
        if (entity === 'bookingdate') {
            if (type === ScreenTypes.EDIT || type === ScreenTypes.VIEW) {
                return <CustomBookingDateScreenButtonList data={data}/>
            }
        }
        return null;
    };

    return (
        <Module
            actions={[
                {name: 'importNivs', element: <ImportNivsScreen/>}
            ]}
            afterFormView={afterFormView}
            beforeFormView={beforeFormView}
            afterList={afterList}
            locale={'es'}
            getSizeFilterModal={getSizeFilterModal}
            filterSelectorValues={filterSelectorValues}
            customScreenButtonList={customScreenButtonList}
        />
    );

}
