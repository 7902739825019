import React, {useState} from 'react';
import {
    Button,
    ButtonClasses,
    Modal,
} from '@cuatroochenta/co-admin-react-library';
import TaskRegistrationRequestManage from '../registrationRequest/ws/TaskRegistrationRequestManage';

interface IProps {
    registrationRequestId: number,
}

export type RegistrationRequestAction = 'accept' | 'reject';

export const RegistrationRequest = (props: IProps): React.ReactElement => {
    const [showRegistrationRequestModal, setShowRegistrationRequestModal] = useState<boolean>(false);
    const [registrationRequestAction, setRegistrationRequestAction] = useState<RegistrationRequestAction | null>(null);

    const registrationRequestManage = (action: RegistrationRequestAction) => {
        new TaskRegistrationRequestManage({action}, props.registrationRequestId)
            .onSuccess(() => window.location.href = '/registrationrequest/list')
            .execute();
        setShowRegistrationRequestModal(false);
    }

    const modalTitle = `${registrationRequestAction === 'accept' ? 'Aceptar' : 'Rechazar'} solicitud de alta`;
    const modalMessage = `Estás a punto de ${registrationRequestAction === 'accept' ? 'aceptar' : 'rechazar'} una solicitud de alta.`;
    const modalAcceptText = registrationRequestAction === 'accept' ? 'Aceptar' : 'Rechazar';

    return (
        <>
            <Button
                content={'Aceptar'}
                onClick={() => {
                    setRegistrationRequestAction('accept');
                    setShowRegistrationRequestModal(true);
                }}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
            />
            <Button
                content={'Rechazar'}
                onClick={() => {
                    setRegistrationRequestAction('reject');
                    setShowRegistrationRequestModal(true);
                }}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_BORDER}
            />
            <Modal
                show={showRegistrationRequestModal}
                title={modalTitle}
                acceptHandler={() => registrationRequestAction && registrationRequestManage(registrationRequestAction)}
                onClose={() => setShowRegistrationRequestModal(false)}
                confirmationModal={true}
                acceptText={modalAcceptText}
            >
                <>
                    <div>{modalMessage}</div>
                    <div>{'¿Deseas continuar?'}</div>
                </>
            </Modal>
        </>
    )
}