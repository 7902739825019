import {useEffect, useState} from "react";
import TaskBookingCancellation, {
    TaskBookingCancellationRequest
} from './ws/TaskBookingCancellation';
import TaskBookingCancellationStructure from './ws/TaskBookingCancellationStructure';

interface UseCustomEditScreenButtonListResponse {
    setShowCancelModal: (show: boolean) => void,
    showCancelModal: boolean,
    isLoading: boolean,
    handleBookingCancellation: () => void,
    disabledAcceptHandler: boolean,
    bookingCancellationFormFields: BookingCancellationFormFields | null,
    cancellationReasonId: string,
    setCancellationReasonId: (reasonId: string) => void,
    reasonText: string,
    setReasonText: (reasonText: string) => void,
}

type BookingCancellationFormFields = {
    cancellationReason: {
        label: string,
        values: { id: number, description: string }[],
    },
    reasonText: {
        label: string,
    }
};

export const useCustomEditScreenButtonListController = (
    experienceId: string,
): UseCustomEditScreenButtonListResponse => {

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bookingCancellationFormFields, setBookingCancellationFormFields] =
        useState<BookingCancellationFormFields | null>(null);
    const [cancellationReasonId, setCancellationReasonId] = useState<string>('');
    const [reasonText, setReasonText] = useState<string>('');
    const [disabledAcceptHandler, setDisabledAcceptHandler] = useState<boolean>(true);

    useEffect(() => {
        if (showCancelModal && !bookingCancellationFormFields) {
            taskBookingCancelationStructure();
        }
    }, [showCancelModal]);

    useEffect(() => {
        setDisabledAcceptHandler(!reasonText || reasonText.length === 0 || !cancellationReasonId);
    }, [reasonText, cancellationReasonId]);

    const taskBookingCancelationStructure = () => {
        setIsLoading(true);
        new TaskBookingCancellationStructure()
            .onSuccess(
                (response: any): void => {
                    const formFields = {
                        cancellationReason: {
                            label: response.form.cancellationReason.label,
                            values: response.form.cancellationReason.values,
                        },
                        reasonText: {
                            label: response.form.reasonText.label,
                        }
                    }
                    setBookingCancellationFormFields(formFields);
                    setIsLoading(false);
                }
            )
            .onFail(() => setIsLoading(false))
            .execute();
    }

    const handleBookingCancellation = (): void => {
        setIsLoading(true);

        const request: TaskBookingCancellationRequest = {
            experienceId: experienceId,
            cancellationReasonId: cancellationReasonId,
            reasonText: reasonText,
        }
        new TaskBookingCancellation(request)
            .onSuccess(
                (response: any): void => {
                    setIsLoading(false);
                    setShowCancelModal(false);
                    window.location.reload();
                }
            )
            .onFail((error: any) => {
                setIsLoading(false);
                setShowCancelModal(false);
            })
            .execute();
    }


    return {
        setShowCancelModal,
        showCancelModal,
        isLoading,
        handleBookingCancellation,
        disabledAcceptHandler,
        bookingCancellationFormFields,
        cancellationReasonId,
        setCancellationReasonId,
        reasonText,
        setReasonText,
    }
}