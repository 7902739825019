import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface TaskBookingCancellationRequest {
    experienceId: string,
    cancellationReasonId: string,
    reasonText: string,
}

export default class TaskBookingCancellation extends BaseGenericRequest<TaskBookingCancellationRequest, any> {

    private readonly data;

    public constructor(request: TaskBookingCancellationRequest) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate(`bookingcancellation/new`));
        this.data = {
            entity: {
                experience: request.experienceId,
                cancellationReason: request.cancellationReasonId,
                reasonText: request.reasonText,
            },
            relations: {},
        }
    }

    protected getRequest(): any {
        return this.data;
    }
}