import {BookingDateId} from '../../../domain/valueObject/BookingDateId';
import {BookingDateRepository} from '../../../domain/repository/BookingDateRepository'
import {LexusApiclientResponse} from '../../../../../data/remote/lexus/LexusApiClient';

export class ApiBookingDateRepository implements BookingDateRepository {

    constructor(private apiClient: LexusApiclientResponse) {};

    public async downloadBookingDatePdf(bookingDateId: BookingDateId): Promise<Response> {
        return await this.apiClient.getDownloadBookingDatePdf({
            bookingDateId: bookingDateId.value,
        });
    }
}