import {ReactElement} from 'react';
import {
    Button,
    ButtonClasses,
} from '@cuatroochenta/co-admin-react-library';
import {
    useCustomBookingDateScreenButtonListController
} from './useCustomBookingDateScreenButtonListController';

interface CustomBookingDateScreenButtonListProps {
    data: any,
}

export const CustomBookingDateScreenButtonList = (props: CustomBookingDateScreenButtonListProps): ReactElement => {

    const UseCustomBookingDateScreenButtonListController =
        useCustomBookingDateScreenButtonListController(props.data.oid);

    return (
        <Button
            content={'Exportar Reserva'}
            onClick={UseCustomBookingDateScreenButtonListController.downloadPdf}
            buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
        />
    )
}