import React, {ReactElement} from 'react';
import {
    Button,
    ButtonClasses,
    Modal,
    SelectorField,
    TextareaField,
    ModalSize,
} from '@cuatroochenta/co-admin-react-library';
import {Status} from './component/Status';
import './customEditScreenButtonList.scss';
import {useCustomEditScreenButtonListController} from './useCustomEditScreenButtonListController';

interface CustomEditScreenButtonListProps {
    data: any,
}

export const CustomEditScreenButtonList = (props: CustomEditScreenButtonListProps): ReactElement => {

    const UseCustomEditScreenButtonListController = useCustomEditScreenButtonListController(
        props.data.oid,
    );

    return (
        <>
            <Status status={props.data.status}/>
            {
                props.data.status !== 'Cancelada' &&
                <Button
                    content={'Cancelar'}
                    onClick={() => UseCustomEditScreenButtonListController.setShowCancelModal(true)}
                    buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
                />
            }

            <Modal
                show={UseCustomEditScreenButtonListController.showCancelModal}
                confirmationModal={true}
                title={'Cancelar experiencia'}
                acceptText={'Cancelar experiencia'}
                isLoading={UseCustomEditScreenButtonListController.isLoading}
                size={ModalSize.LG}
                onClose={() => UseCustomEditScreenButtonListController.setShowCancelModal(false)}
                acceptHandler={UseCustomEditScreenButtonListController.handleBookingCancellation}
                className={"component-custom-edit-screen-button-list"}
                headingText={`Estás a punto de cancelar la experiencia ${props.data.name}`}
                disabledAcceptHandler={UseCustomEditScreenButtonListController.disabledAcceptHandler}
                showCloseButton={true}
            >
                {
                    !UseCustomEditScreenButtonListController.isLoading &&
                    UseCustomEditScreenButtonListController.bookingCancellationFormFields ?
                        <>
                            <div className={"form-group"}>
                                <div className={"component-show-field-form"}>
                                    <SelectorField
                                        name={'cancellationReason'}
                                        label={`${UseCustomEditScreenButtonListController.bookingCancellationFormFields?.cancellationReason.label}*`}
                                        value={UseCustomEditScreenButtonListController.cancellationReasonId}
                                        values={UseCustomEditScreenButtonListController.bookingCancellationFormFields?.cancellationReason.values}
                                        onChange={UseCustomEditScreenButtonListController.setCancellationReasonId}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className={"form-group"}>
                                <div className={"component-show-field-form"}>
                                    <TextareaField
                                        name={'reasonText'}
                                        label={`${UseCustomEditScreenButtonListController.bookingCancellationFormFields?.reasonText.label}*`}
                                        value={UseCustomEditScreenButtonListController.reasonText}
                                        onChange={UseCustomEditScreenButtonListController.setReasonText}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </> :
                        <></>
                }
            </Modal>
        </>
    )
}