import {
    getUrlEndpointPrivate,
    AuthManager,
} from '@cuatroochenta/co-admin-react-library';
import {DownloadBookingDatePdfRequest} from '../lexus/dto/request/bookingDate/DownloadBookingDatePdfRequest';

export interface LexusApiclientResponse {
    getDownloadBookingDatePdf: (request: DownloadBookingDatePdfRequest) => Promise<Response>,
}

const LexusApiClient = (): LexusApiclientResponse => {

    const authToken = AuthManager.getAuthToken();

    const downloadBookingDatePdf = async (request: DownloadBookingDatePdfRequest): Promise<Response> => {
        return await fetch(getUrlEndpointPrivate(`bookingDates/${request.bookingDateId}/generate-pdf`), {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
    };

    return {
        getDownloadBookingDatePdf: downloadBookingDatePdf,
    }
};

export default LexusApiClient;
