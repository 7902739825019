import {ReactElement} from 'react';
import {StatusClasses} from './StatusClasses';
import './status.scss';

interface StatusProps {
    status: string,
}

export const Status = (props: StatusProps): ReactElement => {

    let statusClass = "";
    if (props.status === 'Activa') {
        statusClass = StatusClasses.ACTIVE;
    }
    if (props.status === 'Inactiva') {
        statusClass = StatusClasses.INACTIVE;
    }
    if (props.status === 'Cancelada') {
        statusClass = StatusClasses.CANCELLED;
    }

    return (
        <div className={"component-status"}>
            <div className={statusClass}></div>
            <div>{props.status}</div>
        </div>
    )
}