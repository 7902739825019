import {BookingDateId} from '../valueObject/BookingDateId';
import {ApiBookingDateRepository} from '../../infrastructure/repository/api/ApiBookingDateRepository';

interface Props {
    bookingDateRepository: ApiBookingDateRepository,
    bookingDateId: BookingDateId,
}

export default async (props: Props): Promise<Response> => {
    return await  props.bookingDateRepository.downloadBookingDatePdf(props.bookingDateId);
}