import React, {useState} from 'react';
import {ImportNivsView} from "./ImportNivsView";
import TaskImportNivs from "../../ws/TaskImportNivs";

export interface ResponseNivs {
    response: {
        success: boolean,
        message?: string | string[] | null,
        error: string | null,
    }
}

export const ImportNivsContainer = ({}): React.ReactElement => {
    const [file, setFile] = useState<File | null>(null);
    const [response, setResponse] = useState<ResponseNivs | null>(null);

    const importNivs = (file: File, stopLoading: () => void): void => {
        new TaskImportNivs().get(file)
            .catch(
                (response) => {
                    setResponse({response: {success: false, error: response.message}});
                }
            )
            .then(
                (response) => {
                    if (response !== undefined) {
                        setResponse({response: response});
                    }
                    stopLoading();
                }
            )
    }

    return (
        <ImportNivsView
            importNivs={importNivs}
            responseNivs={response}
        />
    );
}

export default ImportNivsContainer;